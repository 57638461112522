<template>
<div id="app">
  <main>
    <div class="search-box">
      
      <input 
      type="text" 
      name="" 
      id="" 
      class="search-bar" 
      placeholder="Sprawdź pogodę ..."
      v-model="query"
      @keypress="fetchWeather"
      />     
    </div> 
    <!-- <p>Sprawdź promieniowanie słoneczne</p> 
      <div class="szerdl">
      <input 
      type="text" 
      class="lonlat" 
      placeholder="Wpisz szerokość geograficzną"
      />
      <input 
      type="text" 
      class="lonlat" 
      placeholder="Wpisz długość geograficzną"
      />
      </div> -->
    <div class="weather-wrap" v-if="typeof weather.main != 'undefined'">
      <div class="location-box">
        <div class="location">{{ weather.name }}, {{ weather.sys.country }}</div>
        
        <div class="date">{{dateBuilder()}} </div>
      </div>
      
      <div class="wheather-box">
         
        <div class="temp flexpres presleft">
          <div class="pressure">
         <div> 
     
          Pogoda: {{weather.weather[0].description}}
          </div>
         <div>Ciśnienie atmosferyczne: {{weather.main.pressure}} hPa
         </div>
          <div> 
          Wilgotność powietrza: {{weather.main.humidity}} %
          </div>
          <div>
             Temperatura odczuwalna: {{Math.round(weather.main.feels_like)}}°C
            </div>
             <div>
            Prędkość wiatru: {{Math.round(weather.wind.speed)}} km/h
            </div>
         </div>
         <div class="tempclouds">
            <div  class="raincloud">
           <img v-bind:src="showIcon()" width="130">
           </div>   
            {{Math.round(weather.main.temp)}}°C
        </div>
        </div>
        
         <div class="description">   </div>
      
      </div>
    </div>
    
  </main>
  </div>

 </template>
<script>

export default {
 name: 'app',
 data () {
   return {
   api_key: '75dd1584e698274ce0f471d7c5bf5111',
   url_base: 'https://api.openweathermap.org/data/2.5/',
   query: '',
   weather: {},
   lon: '',
   lat: '',
   pressure: '1010',
   icoUrl: 'https://openweathermap.org/img/wn/',
   iconicImg: ['01d','01n', '02d', '02n', '03d', '03n', '04d', '04n', '09d', '09n', '10d', '10n', '11d', '11n', '13d', '13n', '50d', '50n']
   
   
   }
 },
 methods: {

showIcon () {
     for(let i=1; i<=this.iconicImg.length; i++ ){
     if (this.weather.weather[0].icon === this.iconicImg[i]) {
     console.log(i)
     return `${this.icoUrl}${this.iconicImg[i]}@2x.png`;
     
     }
     }
   },

   
   
   fetchWeather (e) {
     
     if (e.key == "Enter") {
       fetch(`${this.url_base}weather?q=${this.query}&units=metric&lang=pl&APPID=${this.api_key}`)
       .then(res => {
         return res.json();
       }).then(this.setResults);
     }
   },
   setResults (results) {
     this.weather = results;
     
     
   },
 
   


   dateBuilder () {
      let d = new Date();
      let months = ["Styczeń", "Luty", "Marzec", "Kawiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"];
      let days = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"];
      let day = days[d.getDay()];
      let date = d.getDate();
      let month = months[d.getMonth()];
      let year = d.getFullYear();
      return `${day} ${date} ${month} ${year}`;
   }
 }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
 body {
   font-family: 'montserrat', sans-serif;
 }
 #app {
   background-image: url('./assets/pogoda-tlo.jpg');
   background-size: cover;
   background-position: bottom;
   transition: 0.4s;
 }
 main {
   min-height: 100vh;
   padding: 25px; 

   background-image: linear-gradient(to bottom, rgba(0,0,0,0.25), rgba(0,0,0,0.75));
 }
 .search-box {
   width: 70%;
   margin: auto;
   margin-bottom: 30px;
 }

 .search-box .search-bar {
   display: block;
   width: 100%;
   padding: 15px;
   color: #464343;
   font-size: 20px;

   appearance: none;
   border: none;
   outline: none;
   background: none;

   box-shadow: 0 0 7px rgba(0,0,0,0.25);
   background-color: rgba(255,255,255, 0.16);
   border-radius: 0px 16px 0px 16px;
   transition: 0.4s;
 }

 .search-box .search-bar:focus { 
   box-shadow: 0 0 16px rgba(0,0,0,0.25);
   background-color: aliceblue;
   border-radius: 16px 0 16px 0 ;
 }

 .location-box .location {
   color: #fff;
   font-size: 32px;
   font-weight: 500;
   text-align: center;
   text-shadow: 1px 3px rgba(0,0,0,0.25);
 }
 .location-box .date {
   color: #fff;
   font-size: 18px;
   font-weight:100;
   font-style: italic;
   text-align: center;
 }
 .wheather-box {
  text-align: center;
 }

 .wheather-box .temp {
   display: inline-box;
   padding: 10px 25px;
   color: #fff;
   font-size: 102px;
   font-weight:900;

   text-shadow: 3px 6px rgba(0,0,0,0.25);
   background-color: rgba(255, 255, 255, 0.25);
   border-radius: 16px;
   margin: 0 auto;
   box-shadow: 3px 6px rgba(255, 255, 255, 0.25);
   max-width: 70%;
 }

 .wheather-box .wheather {
   color:#fff;
   font-size: 46px;
   font-weight: 700;
   font-style: italic;
   text-shadow: 3px 6px rgba(255, 255, 255, 0.25);
 }
 .description {
   display: inline-box;
   padding: 10px 25px;
   color: #fff;
   font-size: 20px;
   font-weight:900;
   margin:10px auto;
   max-width: 70%;

   text-shadow: 3px 3px rgba(0,0,0,0.25);
   background-color: rgba(255, 255, 255, 0.25);
   border-radius: 16px;
   box-shadow: 3px 6px rgba(255, 255, 255, 0.25);
 }
 .szerdl .lonlat {
   width: 20%;
   padding: 15px;
   color: #464343;
   font-size: 20px;
   margin: 0 10px 0 0;

   appearance: none;
   border: none;
   outline: none;
   background: none;

   box-shadow: 0 0 7px rgba(0,0,0,0.25);
   background-color: rgba(255,255,255, 0.16);
   border-radius: 0px 16px 0px 16px;
   transition: 0.4s;
 }
 .szerdl .lonlat:focus { 
   box-shadow: 0 0 16px rgba(0,0,0,0.25);
   background-color: aliceblue;
   border-radius: 16px 0 16px 0 ;
 }
 .pressure {
   font-size: 20px;
   margin: 0 0 100px 0;
   
 }
 .flexpres {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
 }
 .presleft {
   display: flex;
   text-align: left;
 }
 .raincloud {
    position:absolute;
    margin: -80px 0 0 50px; 
 }
 .tempclouds {
   
   margin: auto;
 }
 .labeltext {
   font-size: 25px;
   color: aliceblue;
 }
</style>
